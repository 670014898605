const hardStopText = "We're sorry you don't qualify";
const hardStopBodyText = "We're not able to prescribe for you today";

/**
 * Answer with conditionId is for questions with mutiple choices, each choice might have different
 * condtionId, and it will decide to show next questions based on the matching question's `id`
 * value.
 * Some question with  `conditionId` at the top level, will always go to the next question where
 * conditionId === id
 */
export const questionnaire = [
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question: "Please list your medical conditions and any prior surgeries:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Please list what you’re allergic to and the reaction that each allergy causes.",
    answers: ["allergies"],
    subText:
      "Include any allergies to food, dyes, prescriptions or over the counter medicines (e.g. antibiotics, allergy medications), herbs, vitamins, supplements or anything else.",
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "What is your sex assigned at birth?",
    answers: [
      {
        answer: "Female",
        showConditional: true,
      },
      {
        answer: "Male",
        showConditional: false,
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question: "Are you currently pregnant or planning to become pregnant soon?",
    answers: [
      {
        answer: "Yes",
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "No",
        showConditional: true,
      },
    ],
    type: "radio",
    isConditional: true,
  },
  // TODO: debug why it doesn't show up
  {
    question: "Are you currently breastfeeding?",
    answers: [
      {
        answer: "Yes",
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "No",
        showConditional: true,
      },
    ],
    type: "radio",
    isConditional: true,
  },
  {
    question:
      "Please review and agree to the following breastfeeding/pregnancy precaution",
    subText:
      "Read the following for more information abou this product and its potential side effects",
    text: [
      {
        title: "Pregnancy and Breastfeeding Precaution",
        body: 'The FDA says that these medications may pose a risk to the developing fetus (baby) if you get pregnant while taking it. "The pill" (oral contraception) is not enough because this medication can interfere with the effectiveness. For birth control you must use condoms, an IUD, or an implanted device. Official FDA recommendation: For the first month after first starting a weight loss medication, and for the first month after each dose escalation, continue using your oral contraception and add a barrier method of contraception (such as condoms) to prevent pregnancy. Alternatively, you can switch to a non-oral contraceptive method (such as an IUD or implant) before starting your medication. When you stop the medication, you must continue using a backup method (like condoms) for two months after stopping to allow the medication to fully leave your system before getting pregnant. Similarly, it is not known to be safe in breastfeeding. If you are breastfeeding, consult your doctor for safe weight loss options',
      },
    ],
    answers: [
      {
        text: "By checking this box, you acknowledge the above information",
        answer: "Yes",
        showConditional: false,
      },
    ],
    type: "radio",
    standardDemo: "consent", //needed for styling of consent/guideline questions
    isConditional: true,
  },
  {
    question:
      "Are you currently taking a GLP-1 medication such as Ozempic, Wegovy, Zepbound, Mounjaro, Semaglutide, Tirzepatide?",
    answers: [
      {
        answer: "Yes",
      },
      {
        answer: "No",
      },
    ],
    type: "radio",
  },
  {
    question: "What is your height?",
    subText: "Height in inches",
    answers: ["number"],
    type: "number",
    standardDemo: "height",
    isConditional: false,
  },
  {
    question: "What is your weight?",
    subText: "Weight in pounds (lbs)",
    answers: ["number"],
    type: "number",
    standardDemo: "weight",
    isConditional: false,
  },
  {
    id: "medicalConditions",
    question: "Current and past medical conditions",
    answers: [
      {
        answer: "Gastroparesis",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Inflammatory bowel disease",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Pancreatitis",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Pancreatic cancer",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Gallbladder disease",
        showConditional: true,
        conditionId: "gallbladder", //required to display the next conditional question related to this answer, the conditional question needs to have the same id
      },
      {
        answer: "Hypertriglyceridemia (elevated triglycerides)",
        showConditional: true,
        conditionId: "triglycerides", //required to display the next conditional question related to this answer, the conditional question needs to have the same id
      },
      {
        answer: "Advanced kidney disease",
        showConditional: true,
        conditionId: "medicalConditionsDetails", //required to display the next conditional question related to this answer, the conditional question needs to have the same id
      },
      {
        answer: "Insulin-dependent diabetes",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Thyroid cancer",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Family history of thyroid cancer",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Family history of MEN-2 syndrome",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "PCOS with fertility problems",
        showConditional: true,
        conditionId: "medicalConditionsDetails",
      },
      {
        answer: "Eating disorder, past or present",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Suicidal thoughts in the past 12 months",
        showConditional: true,
        conditionId: "medicalConditionsDetails",
      },
      {
        answer: "Anorexia or bulemia",
        showConditional: false,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
  },
  {
    id: "medicalConditionsDetails",
    question:
      "Please tell us more about your medical condition(s) that you selected",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    id: "gallbladder",
    question: "Things to know about your gallbladder",
    subText:
      "Read the following for more information abou this product and its potential side effects",
    text: [
      {
        title: "Gallbladder disease information",
        body: "You noted that you have had gallbladder stones, problems, or your gallbladder removed. This medication is still a good option however the primary concern is that this medication can affect the way the body handles fats and bile. Without a gallbladder, the body's ability to store and release bile is altered. Bile is crucial for the digestion and absorption of fats. This medication may increase the likelihood of gastrointestinal side effects in these individuals because it can alter fat metabolism and bile flow. This can lead to symptoms such as diarrhea and stomach pain.",
      },
      {
        title: "Risks of gallbladder disease and weight loss medication",
        body: "Additionally, medications that affect digestion and appetite, like this medication, might alter the absorption and metabolism of other nutrients (like fat soluble vitamins such as vitamin A, D, E, and K) and medications. This is particularly important for those without a gallbladder, as their digestive system already operates differently from those with a functioning gallbladder. If you wish to move forward, it is important to eat smaller and more frequent meals. In addition, to ensure that you're receiving enough vitamins, you should avoid processed foods and eat lots of vegetables, as well as considering the use of a multi-vitamin unless told by your provider to avoid these for other reasons.",
      },
    ],
    answers: [
      {
        text: "By checking this box, you acknowledge the above information",
        answer: "Yes",
        showConditional: false,
      },
    ],
    standardDemo: "consent", //needed for styling of consent/guideline questions
    type: "radio",
    isConditional: true,
  },
  {
    id: "triglycerides",
    question: "Have your triglycerides been over 600 at any point?",
    answers: [
      {
        answer: "Yes",
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: true,
  },
  {
    question: "Have you had gastric bypass in the past 6 months?",
    answers: [
      {
        answer: "Yes",
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "No",
        showConditional: true,
      },
    ],
    type: "radio",
  },
  {
    question: "What you should know",
    subText:
      "Read the following for more information about this product and its potential side effects",
    text: [
      {
        title: "General Guidelines",
        body: 'The traditional use of weight loss medications is for individuals with a BMI of 30 and above or to those who are overweight who have associated health conditions. Using it for someone in your BMI range (27-29) without an accompanying health condition is termed "off-label." Using a medication "off-label" refers to the practice of prescribing a drug for a purpose, age group, dosage, or form of administration that is not included in the approved labeling by regulatory agencies like the U.S. Food and Drug Administration (FDA). While a medication undergoes rigorous testing for specific uses before receiving approval, healthcare providers may discover through clinical experience or research that it can be effective for treating other conditions. There may be benefits such as weight reduction for individuals within your range. If you agree to this off label use, it\'s crucial to follow the prescribed regimen and report any concerns. Please discuss any questions with us.',
      },
    ],
    answers: [
      {
        text: "By checking this box, you acknowledge the above information",
        answer: "Yes",
        showConditional: false,
      },
    ],
    type: "radio",
    standardDemo: "consent", //needed for styling of consent/guideline questions
    isConditional: true,
  },
  {
    question: "Are you allergic to any of the following?",
    answers: [
      {
        answer: "Ozempic (Semaglutide)",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Mounjaro (Tirzepatide)",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Wegovy (Semaglutide)",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Zepbound (Tirzepatide)",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "Saxenda (Liraglutide)",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
  },
  {
    question: "Did you take any of the following medications?",
    subText:
      "Insulin, glimepiride (Amaryl), glipizide (Glucotrol and Glucotrol XL), glyburide (Micronase, Glynase, and Diabeta), sitagliptin, saxagliptin, linagliptin, and alogliptin",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
        error: {
          header: hardStopText,
          body: hardStopBodyText,
        },
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    id: "takenSemaOrTir",
    question:
      "Are you currently, or have you in the past two months, taken any of the following medications?",
    answers: [
      {
        answer: "Semaglutide (Ozempic, Wegovy, Saxenda)",
        showConditional: true,
        conditionId: "semaAndTirSideEffectsRadio",
      },
      {
        answer: "Tirzepatide (Zepbound, Mounjaro)",
        showConditional: true,
        conditionId: "semaAndTirSideEffectsRadio",
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
  },

  //for SEMA & TIR path

  {
    id: "semaAndTirSideEffectsRadio",
    question: "Have you experienced side effects from your current medication?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
        conditionId: "semaAndTirSideEffectsText",
      },
      {
        answer: "No",
        showConditional: true,
        conditionId: "matchesDose",
      },
    ],
    type: "radio",
    isConditional: true,
  },
  {
    id: "semaAndTirSideEffectsText",
    question: "Please describe the side effects that you've experienced here",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    conditionId: "matchesDose",
    showConditional: true,
  },
  {
    id: "matchesDose",
    question:
      "Which medication and dose most closely matches your most recent dose?",
    answers: [
      {
        answer: "Semaglutide 0.25mg",
        showConditional: true,
      },
      {
        answer: "Semaglutide 0.5mg",
        showConditional: true,
      },
      {
        answer: "Semaglutide 1mg",
        showConditional: true,
      },
      {
        answer: "Semaglutide 1.7mg",
        showConditional: true,
      },
      {
        answer: "Semaglutide 2.4mg",
        showConditional: true,
      },
      {
        answer: "Tirzepatide 2.5mg",
        showConditional: true,
      },
      {
        answer: "Tirzepatide 5mg",
        showConditional: true,
      },
      {
        answer: "Tirzepatide 7.5mg",
        showConditional: true,
      },
      {
        answer: "Tirzepatide 10mg",
        showConditional: true,
      },
      {
        answer: "Tirzepatide 12.5mg",
        showConditional: true,
      },
      {
        answer: "Tirzepatide 15mg",
        showConditional: true,
      },
    ],
    type: "radio",
    isConditional: true,
  },

  {
    question: "How would you like to continue your treatment?",
    answers: [
      {
        answer: "Stay at the same dose or equivalent dose",
        showConditional: false,
      },
      {
        answer: "Increase dose",
        showConditional: false,
      },
      {
        answer: "Decrease dose",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: true,
  },

  //end SEMA & TIR path

  {
    question: "What other information or questions do you have for the doctor?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: false,
    quickReplyButton: "None", //autofill textarea with value defined here
  },

  {
    question:
      "Please attest to the following confirming that all information provided is true",
    subText:
      "Read the following for more information abou this product and its potential side effects",
    text: [
      {
        title: "Truthfulness consent",
        body: "Consent: I verify that I am the patient and have completed the intake form and acknowledge the following. I have reviewed, understand, and attest that the information I have provided above is true to the best of my knowledge. I understand that it is criticalto my health to share complete health information with my doctor. I will not hold the doctor or affiliated medical practice responsible for any oversights or omissions, whether intentional or not in the information that I provided above.",
      },
    ],
    answers: [
      {
        text: "By checking this box, you acknowledge the above information",
        answer: "Yes",
        showConditional: false,
      },
    ],
    standardDemo: "consent", //needed for styling of consent/guideline questions
    type: "radio",
    isConditional: false,
  },
];
