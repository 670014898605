import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactInputVerificationCode from "react-input-verification-code";

import { dataSecure } from "../DataFiles/standardText";
import { routerMap } from "../utils/router";

import "../MainForm/MainForm.css";
const VerificationCodePlaceHolder = "·";

const DemographicForm = (props) => {
  const { onFormValidated, setValidated, headerRef } = props;

  const [localState, setLocalState] = useState({
    dob: "",
    phone: "",
  });

  const [phoneError, setPhoneError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [isPhoneValidated, setIsPhoneValidated] = useState(false);
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isVerifyingPhone, setIsVerifyingPhone] = useState(false);
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneCodeError, setPhoneCodeError] = useState("");
  const [resendOTPCountdown, setResendOTPCountdown] = useState(30);

  const [showVerificationCodeModal, setShowVerificationCodeModal] =
    useState(false);

  const { verifyPhone, sendPhoneVerification, verifyOTPForPhone } = routerMap;

  useEffect(() => {
    if (resendOTPCountdown === 0) {
      setResendOTPCountdown(null);
    }

    // exit early when we reach 0
    if (!resendOTPCountdown) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setResendOTPCountdown(resendOTPCountdown - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [resendOTPCountdown]);

  const startOtpCountdown = () => {
    setResendOTPCountdown(30);
  };

  const handleCloseVerificationCodeModal = () =>
    setShowVerificationCodeModal(false);
  const handleShowVerificationCodeModal = async () => {
    const result = await sendPhoneVerification(localState.phone, setPhoneError);
    if (result) {
      setShowVerificationCodeModal(true);
      startOtpCountdown();
    }
  };

  const handleVerifyPhoneCode = async () => {
    if (
      verificationCode.length < 6 ||
      verificationCode.includes(VerificationCodePlaceHolder)
    ) {
      setPhoneCodeError("Please enter a 6 digit verification code");
      return;
    }
    setIsVerifyingPhone(true);
    const result = await verifyOTPForPhone(
      localState.phone,
      verificationCode,
      setPhoneCodeError
    );
    setIsVerifyingPhone(false);
    if (result) {
      setIsPhoneVerified(true);
      setVerifiedPhoneNumber(localState.phone);
      handleCloseVerificationCodeModal();
    } else {
      setIsPhoneVerified(false);
    }
  };

  const phoneMask = (phone) => {
    return phone
      .replace(/\D/g, "")
      .replace(/^(\d)/, "($1")
      .replace(/^(\(\d{3})(\d)/, "$1) $2")
      .replace(/(\d{3})(\d{1,4})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const validatePhone = async (phone) => {
    setIsValidatingPhone(true);
    setPhoneError("");
    if (phone.length === 10) {
      const result = await verifyPhone(phone, setPhoneError);
      setIsValidatingPhone(false);
      setIsPhoneValidated(result);
      return result;
    } else {
      setPhoneError("Please enter your cell phone number");
      setIsPhoneValidated(false);
      setIsValidatingPhone(false);
      return false;
    }
  };

  const handleChange = async (e) => {
    const now = new Date();
    const localDob = localState.dob
      ? localState.dob.split("/")
      : [1, 1, now.getFullYear()];
    switch (e.target.name) {
      case "phone":
        e.target.value = phoneMask(e.target.value);
        const cleanedPhone = e.target.value.replace(/\D/g, "");
        if (cleanedPhone.length === 10) {
          if (!isValidatingPhone) {
            const isValidPhone = await validatePhone(cleanedPhone);
            if (isValidPhone) {
              setLocalState((prev) => ({
                ...prev,
                [e.target.name]: cleanedPhone,
              }));
            }
          }
        } else {
          setIsPhoneValidated(false);
        }
        break;
      case "dobMonth":
        const monthValue = e.target.value;
        setLocalState((prev) => ({
          ...prev,
          dob: `${monthValue}/${localDob[1]}/${localDob[2]}`,
        }));
        break;
      case "dobDate":
        const dateValue = e.target.value;
        setLocalState((prev) => ({
          ...prev,
          dob: `${localDob[0]}/${dateValue}/${localDob[2]}`,
        }));
        break;
      case "dobYear":
        const yearValue = e.target.value;
        setLocalState((prev) => ({
          ...prev,
          dob: `${localDob[0]}/${localDob[1]}/${yearValue}`,
        }));
        break;
      default:
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleAgeBlur = () => {
    if (localState.dob.length < 8) {
      setAgeError("Date of birth must be in valid format");
      return;
    }
    ageCheck();
  };

  const ageCheck = () => {
    const now = new Date();
    const eighteenYearsAgo = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate()
    );
    const tooOld = new Date(
      now.getFullYear() - 110,
      now.getMonth(),
      now.getDate()
    );
    const dobArray = localState.dob
      ? localState.dob.split("/")
      : [1, 1, now.getFullYear()];
    const dobMonth = dobArray[0];
    const dobDate = dobArray[1];
    const dobYear = dobArray[2];
    const dob = new Date(dobYear, dobMonth - 1, dobDate);
    if (dob > eighteenYearsAgo) {
      setAgeError(
        "This service is only available for people over the age of 18."
      );
      setValidated(false);
      return false;
    } else if (dob < tooOld) {
      setAgeError("Please enter a valid date of birth");
      setValidated(false);
      return false;
    } else {
      setAgeError("");
      return true;
    }
  };

  useEffect(() => {
    if (
      localState.phone.length &&
      localState.dob.length >= 8 &&
      !ageError &&
      !phoneError &&
      isPhoneVerified &&
      isPhoneValidated
    ) {
      if (localState.phone !== verifiedPhoneNumber) {
        setPhoneError("Please validate your phone number");
        // if they enter a different phone number after, we'll
        // need to have them re-verify their new phone
        setIsPhoneVerified(false);
        setValidated(false);
        return;
      }
      setValidated(true);
      onFormValidated(localState);
    } else {
      setValidated(false);
    }
  }, [
    localState,
    ageError,
    phoneError,
    onFormValidated,
    isPhoneValidated,
    isPhoneVerified,
    verifiedPhoneNumber,
    setValidated,
  ]);

  useEffect(() => {
    headerRef.current.scrollIntoView();
  }, []);

  return (
    <div className="demographicContainer">
      <div className="checkoutForm">
        <div>
          <div className="dataSecureText">
            {dataSecure.header} For more information, click{" "}
            <a
              href="https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
        </div>

        <div className="demographicSectionLabel">Personal Information</div>
        <div className="demographicSubRow emailAndPhone">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">
              Mobile phone number
            </div>
            <div className="phoneInputVerificationHolder">
              <input
                name="phone"
                placeholder="Phone"
                type="tel"
                onChange={handleChange}
                // maxLength='10'
                className={
                  phoneError
                    ? "checkoutInput checkoutInputError"
                    : "checkoutInput"
                }
              />
              {isPhoneVerified ? (
                <div className="phoneVerifiedIndication">
                  <div className="checkMark"></div>
                </div>
              ) : (
                <button
                  disabled={!isPhoneValidated}
                  className="phoneVerificationButton orderButton"
                  onClick={handleShowVerificationCodeModal}
                >
                  Verify
                </button>
              )}
            </div>
            {phoneError && <div className="checkoutError">{phoneError}</div>}
          </div>

          <Modal
            id="phoneVerificationModal"
            show={showVerificationCodeModal}
            onHide={handleCloseVerificationCodeModal}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Verify</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="my-2">
                Please enter the verification code that we sent to your phone
                number.
              </div>
              <div className="custom-styles">
                <ReactInputVerificationCode
                  length={6}
                  autoFocus={true}
                  onChange={(value) => {
                    if (value && !value.includes("·")) {
                      setVerificationCode(value);
                      // TODO: do we want to automatically submit?
                      // handleVerifyPhoneCode(value);
                    }
                  }}
                />
              </div>

              <div className="resendOtpHolder">
                {resendOTPCountdown === null ? (
                  <div
                    className="resendOtpButton"
                    onClick={handleShowVerificationCodeModal}
                  >
                    Re-send OTP
                  </div>
                ) : (
                  <div className="resendOtpCountdown">
                    Didn't receive a code? Re-send in {resendOTPCountdown}s...
                  </div>
                )}
              </div>

              {phoneCodeError && (
                <div className="checkoutError">{phoneCodeError}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="closeVerificationCodeModal"
                disabled={isVerifyingPhone}
                variant="secondary"
                onClick={handleCloseVerificationCodeModal}
              >
                Close
              </Button>
              <Button
                id="confirmVerificationCodeModal"
                disabled={isVerifyingPhone || verificationCode.length < 6}
                variant="primary"
                onClick={handleVerifyPhoneCode}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          {/* this is intended */}
          <div className="innerSubRow"></div>
        </div>
        <div className="demographicSubRow dobAndAddress">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Date of birth</div>
            <div className="dobInputs">
              <input
                name="dobMonth"
                placeholder="MM"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobDate"
                placeholder="DD"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobYear"
                placeholder="YYYY"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputShort checkoutInputError"
                    : "checkoutInputShort"
                }
                maxLength="4"
              />
            </div>
            {ageError && <div className="checkoutError">{ageError}</div>}
          </div>
          {/* this is intended */}
          <div className="innerSubRow"></div>
        </div>
      </div>
    </div>
  );
};

export default DemographicForm;
